@use '@angular/cdk';
@use './tokens/m2/mat/app' as tokens-mat-app;
@use './tokens/token-utils';
@use './ripple/ripple';
@use './focus-indicators/private';
@use './mdc-helpers/mdc-helpers';

// Mixin that renders all of the core styles that are not theme-dependent.
@mixin core() {
  @include ripple.ripple();
  @include cdk.a11y-visually-hidden();
  @include cdk.overlay();
  @include cdk.text-field-autosize();
  @include cdk.text-field-autofill();
  @include private.structural-styling('mat');
  @include private.structural-styling('mat-mdc');

  // Wrapper element that provides the theme background when the
  // user's content isn't inside of a `mat-sidenav-container`.
  @at-root {
    .mat-app-background {
      @include mdc-helpers.disable-mdc-fallback-declarations {
        @include token-utils.use-tokens(tokens-mat-app.$prefix, tokens-mat-app.get-token-slots()) {
          // Note: we need to emit fallback values here to avoid errors in internal builds.
          @include token-utils.create-token-slot(background-color, background-color, transparent);
          @include token-utils.create-token-slot(color, text-color, inherit);
        }
      }
    }
  }
}
