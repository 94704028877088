/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.badge {
  @apply font-sans text-sm leading-4 whitespace-nowrap  py-1 px-[10px] rounded-[70px];

  &-green {
    @apply bg-crm-green-500 text-[#0B0B0B];
  }
  &-green-legacy {
    @apply bg-crm-green-300 text-[#0B0B0B];
  }

  &-red {
    @apply bg-crm-red-500 text-white;
  }

  &-blue {
    @apply bg-crm-cyan-500 text-[#0B0B0B];
  }

  &-yellow {
    @apply bg-crm-yellow-500 text-[#0B0B0B];
  }

  &-purple {
    @apply bg-crm-purple-500 text-white;
  }

  &-gray {
    @apply bg-gray-500 text-white;
  }

  &-orange {
    @apply bg-orange-500 text-[#0B0B0B];
  }

  &-primary {
    @apply bg-primary text-white;
  }
  &-primary-legacy {
    @apply bg-primary-300 text-white;
  }
}

.country-select {
  &.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open {
    margin-top: 37px !important;
    width: 100% !important;
    border-bottom: 1px solid #e0e0e0;
    input {
      width: 100% !important;
      padding-top: 6px;
      background: #fff;
      z-index: 9999;
    }
  }
}

.tx-paginator {
  .mat-mdc-paginator-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .mat-mdc-text-field-wrapper {
      max-height: 32px !important;
      .mat-mdc-form-field-infix {
        padding-top: 3px;
        padding-bottom: 0;
      }
    }
  }
}
